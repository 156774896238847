import React, { useContext }  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faHome, faChartLine,faTrash, faDatabase, faToiletPaperSlash} from '@fortawesome/free-solid-svg-icons';
import {
    Link
  } from "react-router-dom"
import Utilities from '../../../js/Utilities';
import './ReportsHeader.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import UserContext from '../../../js/UserContext';



function HeaderButton(props){
    return <Link className={`headerBtn${props.active ? ' btnActive' : ''}`} to={props.to}> 
    <FontAwesomeIcon className='btnIcon' icon={props.icon} size='1x'/>
    <Typography>{props.title}</Typography>
</Link>
  }

function ReportsHeader(props){
    const location = useLocation();
    const { authUser, adminDetails } = useContext(UserContext);

    
    if(firebase.auth().currentUser && !firebase.auth().currentUser.email.endsWith("@gottea.in")){
        firebase.auth().signOut();
    }
    return <React.Fragment>{adminDetails ? <div className="headerContainer">
    <HeaderButton to={'/'} active={location.pathname === '/'} icon={faHome} title={'Home'}/>
    <HeaderButton to={'/wastage'} active={location.pathname === '/wastage'} icon={faTrash} title={'Wastage'}/>
    {adminDetails && adminDetails.role === "admin" ? <>
    <HeaderButton to={'/materials'} active={location.pathname === '/materials'} icon={faDatabase} title={'Materials'}/>
    <HeaderButton to={'/stats'} active={location.pathname === '/stats'}  icon={faChartLine} title={'COGs'}/>
    <HeaderButton to={'/wastage_stats'} active={location.pathname === '/wastage_stats'}  icon={faToiletPaperSlash} title={'Wastage COGs'}/>
    </> : null}
    <div className='headerBtn logOutBtn' onClick={Utilities.logout}> 
        <FontAwesomeIcon className='btnIcon' icon={faSignOutAlt} size='2x'/>
        Log Out
    </div>
</div> : null}
<p className="welcomeMsg">{authUser ? `Hello, ${authUser.displayName}` : 'Not logged in'}</p>
</React.Fragment>

}

export default ReportsHeader;