import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import './Materials.css';
import Loader from '../../Common/Loader/Loader';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { TablePagination } from '@mui/material';

import { debounce } from "debounce";
const Client = new Api();
const moment = require('moment');

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.75),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const categories = [
  "Drink ingredients",
  "Food Ingredients",
  "Packaging",
  "Merchandise",
  "Disposables",
  "Petcan stickers (350 ml)",
  // "Petcan stickers (475 ml)",
  "Snacks box Sticker"
];


class Materials extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentPage:0,
            pageSize: 10,
            totalItems: 0,
            searchTerm:'',
            loading:false,
            materialsData:[],
            materialCategory:'Drink ingredients',
            editMode:false,
            activeMatId:'',
            selectedUnit:'g',
            selectedIncrement:0.5,
            selectedStatus:'Active',
            selectedDisplayOrder:0,
            fieldErrors:{},
            showAddModal:false,
            deleteConfirmationShown:false,
            browser : Utilities.getCurrentBrowser(),
        }
    }

    loadMaterials = ()=>{
        this.setState({
            loading:true
        },()=>{
            Client.searchMaterials(this.state.searchTerm, null, this.state.currentPage + 1, this.state.pageSize).then((data)=>{
                this.setState({
                    loading:false,
                    totalItems:data.totalCount,
                    materialsData:data.data
                });
            })
        })
    }

    handlePageChange = (event, value) => {
      this.setState({ currentPage: value }, this.loadMaterials);
    }

    handleRowsPerPageChange = (event) => {
      this.setState({ pageSize: parseInt(event.target.value, 10), currentPage: 0 }, this.loadMaterials);
    }

    debouncedMaterials = debounce(this.loadMaterials,400);
    componentDidMount(){
        this.loadMaterials();
    }

    handleSearch = (e)=>{
        this.setState({
            currentPage:0,
            searchTerm:e.target.value
        },()=>{
            this.debouncedMaterials();
        });
    }

    showEditModal = (materialId)=>{
      Client.getMaterial(materialId).then((materialData)=>{
        this.setState({
          activeMatId:materialId,
          selectedIncrement:materialData.incrementUnit,
          selectedStatus:materialData.status || "Active",
          selectedDisplayOrder:materialData.displayOrder || 0,
          materialCategory:materialData.category,
          materialName:materialData.name,
          selectedUnit:materialData.unit,
          pricePerUnit:materialData.pricePerUnit,
          unitSize:materialData.unitSize,
          showAddModal:true,
          editMode:true
        });
      })
    }

    showDeleteModal = (materialName, materialId)=>{
      this.setState({
        activeMatId:materialId,
        activeMaterialName:materialName,
        deleteConfirmationShown:true
      });
    }

    handleShowAddMaterial = (e)=>{
      this.resetModal(()=>{
        this.setState({
          showAddModal:true,
        });
      });
    }

    handleCloseModals = (e)=>{
      this.resetModal(()=>{
        this.setState({
          showAddModal:false,
          deleteConfirmationShown:false
        });
      });
    }

    handleFieldChange = (fieldName, e)=>{
      let fieldErrorsCopy = {...this.state.fieldErrors};
      delete fieldErrorsCopy[fieldName];
      this.setState({
        [`${fieldName}`]:e.target.value,
        fieldErrors:fieldErrorsCopy
      },()=>{

      });
    }

    resetModal = (cb)=>{
      this.setState({
        fieldErrors:{},
        materialName:'',
        activeMatId:'',
        activeMaterialName:'',
        pricePerUnit:null,
        unitSize:null,
        editMode:false
      },()=>{
        cb();
      });
    }

    createMaterial = (e)=>{
      const {editMode, activeMatId, materialName, materialCategory, selectedUnit, selectedIncrement, selectedStatus, selectedDisplayOrder, pricePerUnit, unitSize} = this.state;
      let mainThis = this;
      if(this.validateFields()){
        this.setState({
          loading:true,
          showAddModal:false
        },()=>{
          if(editMode && !Utilities.isNullOrEmpty(activeMatId)){
            Client.updateMaterial(activeMatId,{
              name:materialName,
              category:materialCategory,
              unit:selectedUnit,
              incrementUnit:selectedIncrement,
              status:selectedStatus,
              displayOrder:selectedDisplayOrder,
              pricePerUnit,
              unitSize
            }).then((response)=>{
              mainThis.loadMaterials();
            });
          } else {
            Client.createMaterial({
              name:materialName,
              category:materialCategory,
              unit:selectedUnit,
              status:selectedStatus,
              incrementUnit:selectedIncrement,
              displayOrder:selectedDisplayOrder,
              pricePerUnit,
              unitSize
            }).then(()=>{
              mainThis.loadMaterials();
            });
          }

        })
      }
    }

    deleteMaterial = (id)=>{
      this.setState({
        deleteConfirmationShown:false
      },()=>{
        Client.deleteMaterial(id).then(()=>{
          setTimeout(() => {
            this.loadMaterials();
          }, 600);
        });
      })
    }

    validateFields = ()=>{
      let errors = {};
      if(Utilities.isNullOrEmpty(this.state.materialName)){
        errors['materialName'] = 'Material name is required.';
      }
      if(Utilities.isNullOrEmpty(this.state.pricePerUnit)){
        errors['pricePerUnit'] = 'Price per unit is required.';
      }
      if(Utilities.isNullOrEmpty(this.state.unitSize)){
        errors['unitSize'] = 'Unit size is required.';
      }
      if(!(Utilities.isNullOrEmpty(errors))){
        this.setState({
          fieldErrors:errors
        });
        return false;
      }
      return true;
    }
    
    
    
    render(){
        const totalPages = Math.ceil(this.state.totalItems / this.state.pageSize);
        return  <div className='materialsContainer'><ReportsHeader/>
        <Box sx={{ flexGrow: 1 }}>
        <Stack spacing={1} direction={{ xs: 'column', sm: 'row' }} width='100%'>
        <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          value={this.state.searchTerm}
          onChange={this.handleSearch}
          placeholder="Type Material Name..."
          inputProps={{ 'aria-label': 'search' }}
        />
        </Search>
        <Button color="success" variant="contained" endIcon={<AddIcon />} onClick={this.handleShowAddMaterial}>
        Add Material
        </Button>
        </Stack>
        <Modal
        open={this.state.deleteConfirmationShown}
        onClose={this.handleCloseModals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={{mb:5, textAlign:'center'}}>Are you sure you want to delete <b>{this.state.activeMaterialName}</b>?</Typography>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
                  <Button 
                  color="success" 
                  variant="contained" 
                  startIcon={<EditIcon/>}
                  onClick={()=>this.deleteMaterial(this.state.activeMatId)}
                  >Yes, Delete</Button>
                  <Button 
                  color="error" 
                  variant="contained" 
                  startIcon={<DeleteIcon/>}
                  onClick={this.handleCloseModals}
                  >No, Don't</Button>
          </Stack>
        </Box></Modal>
        <Modal
        open={this.state.showAddModal}
        onClose={this.handleCloseModals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Stack direction="column" spacing={2}>
          <Select
            id="demo-simple-select"
            value={this.state.materialCategory}
            label="Unit"
            onChange={(e)=>{this.handleFieldChange('materialCategory',e)}}
          >
            {categories.map((c,i)=><MenuItem key={`${c}-${i}`} value={c}>{c}</MenuItem>)}
        </Select>
          <TextField 
          id="outlined-basic" 
          error={this.state.fieldErrors['materialName'] ? true :false} 
          helperText={this.state.fieldErrors['materialName']} 
          label="Material Name" variant="outlined" 
          value={this.state.materialName} 
          onChange={(e)=>{this.handleFieldChange('materialName',e)}}
          />
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Increment Unit</InputLabel>
          <Select
            id="demo-simple-select"
            value={this.state.selectedIncrement}
            label="Increment Unit"
            onChange={(e)=>{this.handleFieldChange('selectedIncrement',e)}}
          >
          <MenuItem value={0.5}>0.5</MenuItem>
          <MenuItem value={1}>1</MenuItem>
        </Select>
        </FormControl>
        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Status</InputLabel>
        <Select
            id="demo-simple-select"
            value={this.state.selectedStatus}
            label="Status"
            onChange={(e)=>{this.handleFieldChange('selectedStatus',e)}}
          >
          <MenuItem value={'Active'}>Active</MenuItem>
          <MenuItem value={'Inactive'}>Inactive</MenuItem>
        </Select>
        </FormControl>
        <FormControl fullWidth>
        <TextField 
          id="outlined-basic" 
          type="number"
          error={this.state.fieldErrors['selectedDisplayOrder'] ? true :false} 
          helperText={this.state.fieldErrors['selectedDisplayOrder']} 
          label="Display Order" 
          variant="outlined" 
          value={this.state.selectedDisplayOrder} 
          onChange={(e)=>{this.handleFieldChange('selectedDisplayOrder',e)}}
          />
        </FormControl>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Unit</InputLabel>
          <Select
            id="demo-simple-select"
            value={this.state.selectedUnit}
            label="Unit"
            onChange={(e)=>{this.handleFieldChange('selectedUnit',e)}}
          >
          <MenuItem value={'g'}>Grams(g)</MenuItem>
          <MenuItem value={'ml'}>Millilitres(ml)</MenuItem>
          <MenuItem value={'ea'}>Each(ea)</MenuItem>
        </Select>
        </FormControl>
          <TextField 
          label="Price/Unit" 
          type="number" 
          variant="outlined"  
          value={this.state.pricePerUnit} 
          onChange={(e)=>{this.handleFieldChange('pricePerUnit',e)}}
          error={this.state.fieldErrors['pricePerUnit'] ? true :false} 
          helperText={this.state.fieldErrors['pricePerUnit']} 
          />
          <TextField 
          label="Unit Size" 
          type="number" 
          variant="outlined"  
          value={this.state.unitSize} 
          onChange={(e)=>{this.handleFieldChange('unitSize',e)}}
          error={this.state.fieldErrors['unitSize'] ? true :false} 
          helperText={this.state.fieldErrors['unitSize']} 
          />
          <Button color="success" variant="contained" onClick={this.createMaterial} disabled={!Utilities.isNullOrEmpty(this.state.fieldErrors)}>
          {this.state.editMode ? 'Update' : 'Create'}
          </Button>
          </Stack>
        </Box>
      </Modal>
        </Box>

        <TableContainer sx={{margin:4}} component={Paper}>
        <TablePagination 
        component="div"
        count={this.state.totalItems} 
        page={this.state.currentPage} 
        onPageChange={this.handlePageChange} 
        rowsPerPage={this.state.pageSize}
        onRowsPerPageChange={this.handleRowsPerPageChange}
        />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Category</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Price / Unit</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Unit Size</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.loading ? <TableRow
                key={'loader'}
              >
                <TableCell component="th" scope="row" colSpan={5} sx={{textAlign:'center'}}>
                <Loader/>
                </TableCell>
                </TableRow> : <>{this.state.materialsData.map((row,index) => (
              <TableRow
                key={`${row.name}-${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  {row.category}
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="left">₹{row.pricePerUnit} / {row.unit}</TableCell>
                <TableCell align="left">{row.unitSize}{row.unit}</TableCell>
                <TableCell align="left">
                  <Stack spacing={2} direction="row">
                  <Button 
                  color="success" 
                  variant="contained" 
                  onClick={()=>this.showEditModal(row.id)}
                  ><EditIcon/></Button>
                  <Button 
                  color="error" 
                  variant="contained" 
                  onClick={()=>this.showDeleteModal(row.name,row.id)}
                  ><DeleteIcon/></Button>
                  </Stack>
                  </TableCell>
              </TableRow>
            ))}</>}
            
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    } 
}

export default Materials;