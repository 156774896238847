import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, authUser, adminDetails, adminOnly, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      {
        if(adminOnly){
          console.log("Admin Only Route");
          if(authUser && adminDetails && adminDetails.role === "admin"){
            return <Component {...props} />
          } else {
            return <Redirect to="/login" />
          }
        } else {
          console.log("General Private Route");
          return authUser ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      }

    }
  />
);

export default PrivateRoute;