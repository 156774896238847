import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import './Home.css';
import Loader from '../../Common/Loader/Loader';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EyeIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/AddCircle';
import MinusIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { debounce } from "debounce";
import StatusTag from '../../Common/StatusTag';
import _ from 'lodash';
import PrintButton from '../../Common/PrintButton';
import OrderStats from '../../Common/OrderStats';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { TablePagination } from '@mui/material';
import TextField from '@mui/material/TextField';

const firebaseConfig = {
  apiKey: 'AIzaSyAB10QoTXFcdUCaVSbZntf_d_cSj7OSjHM',
  authDomain: 'gottea-3daa4.firebaseapp.com',
  databaseURL: 'https://gottea-3daa4.firebaseio.com',
  projectId: 'gottea-3daa4',
  storageBucket: 'gottea-3daa4.appspot.com',
  messagingSenderId: '691417546594',
  appId: '1:691417546594:web:536ef6d0c379fb5947b63b',
  measurementId: 'G-ED1W31MB1J',
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);
// Use these for db & auth
const db = firebaseApp.firestore();

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height:'5px'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#91BCB9',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    padding:0,
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#008080',
    },
  }),
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Client = new Api();
const moment = require('moment');

const categories = [
  "Drink ingredients",
  "Food Ingredients",
  "Packaging",
  "Merchandise",
  "Disposables",
  "Petcan stickers (350 ml)",
  // "Petcan stickers (475 ml)",
  "Snacks box Sticker"
];

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth:{xs:'92vw',sm:800},
    maxHeight:'80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY:'auto',
    p: 4,
  };

class Actions extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tabValue:0,
            currentPage:0,
            pageSize: 10,
            totalItems:0,
            currentlyEditingOrder:{orderedBy:''},
            loading:false,
            orderItemsArr:[],
            processedItemsObj:{},
            orderItemsObj:{},
            actionItemsArr:[],
            editingMaterialName:'',
            editMode:false,
            activeMatId:'',
            selectedItem:{},
            selectedQuantity:0,
            confirmedOrder:false,
            fieldErrors:{},
            showAddModal:false,
            deleteConfirmationShown:false,
            showOrderModal:false,
            showAlert:false,
            alertType:"error",
            alertMessage:'',
            browser : Utilities.getCurrentBrowser(),
        }
    }

    goBack = ()=> {
      this.setState({
        confirmedOrder:false
      })
    }

    handleTabChange = (event, newValue)=>{
      this.setState({
        currentPage:0,
        loading:true,
        tabValue:newValue
      },()=>{
        this.loadActions();
      })
    }


    loadActions = ()=>{
        this.setState({
            loading:true
        },()=>{
          let statusArr = [];
          if(this.state.tabValue === 0) {
            statusArr = ["Order Placed","Acknowledged","Dispatched"];
          } else {
            statusArr = ["Delivered","Delivered Partially"];
          }
          Client.searchActionsPaginated('',{actionType:['inventoryOrder'],status:statusArr},this.state.currentPage + 1, this.state.pageSize).then((loadedActions)=>{
              this.setState({
                  actionItemsArr:loadedActions.data,
                  totalItems:loadedActions.totalCount,
                  loading:false,
              });
          })
        })
    }

    // handlePlaceOrder = ()=> {
    //   const {orderItemsObj,actionItemsArr} = this.state;
    //   let action = {};
    //   action.actionType = "inventoryOrder";
    //   action.branch = "Galleria";//TODO: Change Based on Branch
    //   action.status = "Order Placed";
    //   action.data = {itemMap : {...orderItemsObj},items:[]};
    //   action.data.items = actionItemsArr.filter((i)=> { return !Utilities.isNullOrEmpty(orderItemsObj[i.id])});
    //   Client.createNewAction(action).then((res)=>{
    //     alert("Order Placed");
    //   });
    // }

    debouncedMaterials = debounce(this.loadActions,400);

    handlePageChange = (event, value) => {
      this.setState({ currentPage: value }, this.loadActions);
    }
  
    handleRowsPerPageChange = (event) => {
      this.setState({ pageSize: parseInt(event.target.value, 10), currentPage: 0 }, this.loadActions);
    }

    componentDidMount(){
      let firstTime = true;
      this.loadMaterials();
      this.loadActions();
      let mainThis = this;
      db.collection("actions")
      .onSnapshot((querySnapshot) => {
        setTimeout(()=>{
          if(!firstTime){
            mainThis.loadActions();
          }
          firstTime = false;
        },2000);
      });
    }

    loadMaterials = ()=>{
      this.setState({
          loading:true
      },()=>{
        Client.getActiveMaterials().then((data)=>{
              let oItems = [];        
              let catHash = {};        
              for(let item of data){
                if(Utilities.isNullOrEmpty(catHash[item.category])){
                  catHash[item.category] = [];
                }
                catHash[item.category].push({ id:item.id, name : item.name, unitSize:parseFloat(item.unitSize), unit:item.unit});
              }
              for(let c of categories){
                oItems.push({ category : c, items : catHash[c]||[]});
              }
              this.setState({
                  orderItemsArr:oItems,
                  loading:false,
                  materialsData:data
              });
          })
      })
  }

    showEditModal = (selectedItem,materialName, materialId, qty)=>{
        this.setState({
          selectedItem,
          activeMatId:materialId,
          editingMaterialName:materialName,
          showAddModal:true,
          selectedQuantity:qty,
          editMode:true
        });
    }

    showDeleteModal = (rowIndex,materialName, materialId)=>{
      this.setState({
        activeMatId:materialId,
        activeRowIndex:rowIndex,
        deleteConfirmationShown:true
      });
    }

    handleShowAddItem = (e)=>{
      this.resetModal(()=>{
        this.setState({
          showAddModal:true,
        });
      });
    }

    handleCloseModals = (e)=>{
      this.resetModal(()=>{
        this.setState({
          activeMatId:undefined,
          activeRowIndex:undefined,
          showAddModal:false,
          deleteConfirmationShown:false
        });
      });
    }

    handleFieldChange = (fieldName, e)=>{
      let fieldErrorsCopy = {...this.state.fieldErrors};
      delete fieldErrorsCopy[fieldName];
      console.log("field name", fieldName);
      console.log("field errors", fieldErrorsCopy);
      if(fieldName === 'selectedItem'){
        let selectedItem = e;
        this.setState({
          selectedItem,
          fieldErrors:fieldErrorsCopy
        });
      } else {
        console.log("field errors state being set", fieldErrorsCopy);
        this.setState({
          [`${fieldName}`]:e.target.value,
          fieldErrors:fieldErrorsCopy
        },()=>{
  
        });
      }
    }

    resetModal = (cb)=>{
      this.setState({
        editMode:false,
        currentlyEditingOrder:{orderedBy:''},
        fieldErrors:{},
        selectedItem:{},
        selectedQuantity:0
      },()=>{
        cb();
      });
    }

    increaseItemQuantity = (itemId, incrementUnit)=>{
      const {processedItemsObj} = this.state;
      let processedItemsObjCopy = _.cloneDeep(processedItemsObj);
      if(processedItemsObjCopy[itemId]){
        processedItemsObjCopy[itemId].qty += incrementUnit;
      } else {
        processedItemsObjCopy[itemId] = {qty:incrementUnit}
      }
      this.setState({
        processedItemsObj:processedItemsObjCopy
      },()=>{
        console.log(this.state.actionItemsArr);
      })
    }

    decreaseItemQuantity = (itemId, incrementUnit)=>{
      const {processedItemsObj} = this.state;
      let processedItemsObjCopy = _.cloneDeep(processedItemsObj);
      if(processedItemsObjCopy[itemId]){
        processedItemsObjCopy[itemId].qty -= incrementUnit;
      } else {
        processedItemsObjCopy[itemId] = {qty:0}
      }
      if(processedItemsObjCopy[itemId].qty <= 0){
        //delete processedItemsObjCopy[itemId];
        processedItemsObjCopy[itemId].qty = 0;
      }

      this.setState({
        processedItemsObj:processedItemsObjCopy
      })
    }

    deleteItem = (toDeleteIndex)=>{
      this.setState({
        deleteConfirmationShown:false
      },()=>{
        let orderItemsObjCopy = {...this.state.orderItemsObj};
        let actionItemsArrCopy = this.state.actionItemsArr.slice();
        actionItemsArrCopy.splice(toDeleteIndex,1);
        if(orderItemsObjCopy[this.state.activeMatId]){
          delete orderItemsObjCopy[this.state.activeMatId];
        }
        this.setState({
          orderItemsObj:orderItemsObjCopy,
          materialsData:this.state.materialsData,
          actionItemsArr: actionItemsArrCopy
        })
      })
    }

    validateFields = ()=>{
      let errors = {};
      if(Utilities.isNullOrEmpty(this.state.selectedItem)){
        errors['selectedItem'] = 'Item is required.';
      }
      if(Utilities.isNullOrEmpty(this.state.selectedQuantity) || this.state.selectedQuantity <= 0){
        errors['selectedQuantity'] = 'Quantity cannot be zero.';
      }
      if(!(Utilities.isNullOrEmpty(errors))){
        console.log(errors);
        this.setState({
          fieldErrors:errors
        });
        return false;
      }
      return true;
    }

    viewOrder = (order)=> {
      let orderItemsObjCopy = _.cloneDeep(order.data.itemMap);
      let orderItems = _.cloneDeep(order.data.items);
      let processedItemsObj = {};
      if(["Dispatched","Delivered","Delivered Partially"].includes(order.status)){
        processedItemsObj = _.cloneDeep(order.processedData.itemMap);
      } else if(["Acknowledged"].includes(order.status)){
        if(Utilities.isNullOrEmpty(order.processedData)){
          processedItemsObj = _.cloneDeep(order.data.itemMap);
        } else {
          processedItemsObj = _.cloneDeep(order.processedData.itemMap);
        }
      }

      this.setState({
        orderItemsObj:orderItemsObjCopy,
        processedItemsObj,
        orderItemsArr:orderItems,
        currentlyEditingOrder:order,
        confirmedOrder:true,
        editingOrder:true,
        showOrderModal:true
      });
  }
    
    confirmOrder = ()=> {
      if(this.state.confirmedOrder){
        // this.placeOrder();
      } else {
        this.setState({
          confirmedOrder:true
        });
      }
    }

    handleCloseModals = (e)=>{
        e.preventDefault();
        this.setState({
          orderItemsObj:{},
          confirmedOrder:false,
          showOrderModal:false,
          editingOrder:false
        },()=>{
        });
    }

    acceptOrder = ()=> {
      const {currentlyEditingOrder} = this.state;
          let action = {};
          action.status = "Acknowledged";

         this.setState({
          loading:true,
          orderSuccess:true,
          showAlert:true,
          alertType:"success",
          alertMessage:`Successfully updated order with id ${currentlyEditingOrder.id}`,
          showOrderModal:false,
        },()=>{
          Client.updateAction(currentlyEditingOrder.id,action).then((res)=>{
          this.loadActions();
          });
        })
    }

    updateOrder = ()=> {
      const {processedItemsObj,currentlyEditingOrder} = this.state;
      let action = {};
      action.processedData = {itemMap : _.cloneDeep(processedItemsObj),items:[]};
      let items = [];
      let orderItemsArrCopy = _.cloneDeep(this.state.orderItemsArr);
      for(let cat of orderItemsArrCopy){
        if(cat && cat.items.length > 0){
          let temp = [];
          let newObj = {};
          for(let item of cat.items){
            if(processedItemsObj[item.id]){
              temp.push(item);
            }
          }
          newObj = {category:cat.category, items: temp};
          items.push(newObj);
        }
      }
      action.processedData.items = items;
      this.setState({
        loading:true,
        showOrderModal:false,
      },()=>{
        Client.updateAction(currentlyEditingOrder.id,action).then((res)=>{
          this.loadActions();
        });
      })

    }

    dispatchOrder = ()=> {
      const {processedItemsObj,currentlyEditingOrder} = this.state;
      let action = {};
      action.status = "Dispatched";
      action.processedData = {itemMap : _.cloneDeep(processedItemsObj),items:[]};
      let items = [];
      let orderItemsArrCopy = _.cloneDeep(this.state.orderItemsArr);
      for(let cat of orderItemsArrCopy){
        if(cat && cat.items.length > 0){
          let temp = [];
          let newObj = {};
          for(let item of cat.items){
            if(processedItemsObj[item.id]){
              temp.push(item);
            }
          }
          newObj = {category:cat.category, items: temp};
          items.push(newObj);
        }
      }
      action.processedData.items = items;
      this.setState({
        loading:true,
        showOrderModal:false,
      },()=>{
        Client.updateAction(currentlyEditingOrder.id,action).then((res)=>{
        this.loadActions();
        });
      })

    }
    
    renderActionsTable(){
      const {actionItemsArr} = this.state;
      return  <TableContainer sx={{margin:0, maxHeight:'60vh', maxWidth:'94vw'}} component={Paper}>
      <TablePagination 
        component="div"
        count={this.state.totalItems} 
        page={this.state.currentPage} 
        onPageChange={this.handlePageChange} 
        rowsPerPage={this.state.pageSize}
        onRowsPerPageChange={this.handleRowsPerPageChange}
        />
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Order ID</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Order Time</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Branch</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Status</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.loading ? <TableRow
              key={'loader'}
            >
              <TableCell component="th" scope="row" colSpan={5} sx={{textAlign:'center'}}>
              <Loader/>
              </TableCell>
              </TableRow> : <>{actionItemsArr.map((row,index) => (
            <TableRow
              key={`${row}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
              {row.id}
              </Typography>
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
              {moment(row.createdAt).format("ddd DD-MMM-YYYY, hh:mm A")}
              </Typography>
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
              <StatusTag status={row.branch}/>
              </Typography>
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
              <StatusTag status={row.status}/>
              </Typography>
              </TableCell>
              <TableCell align="left">
              <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
              <Button color="success" variant="contained" children={<EyeIcon />} onClick={()=>this.viewOrder(row)}/>
              </Stack>
                </TableCell>
            </TableRow>
          ))}</>}
          
        </TableBody>
      </Table>
    </TableContainer>
    }

    handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      this.setState({
        showAlert:false
      });
    }
  
    alertAction = ()=> (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={this.handleCloseAlert}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );
      
    render(){
        const {orderItemsObj, processedItemsObj, orderItemsArr,tabValue,showAlert, alertType, alertMessage } = this.state;

        return  <div className='homeContainer'><ReportsHeader/>
        <Box sx={{padding:0}}>
        <Snackbar
        open={showAlert}
        autoHideDuration={6000}
        onClose={this.handleCloseAlert}
        action={this.alertAction()}
      >
        <Alert onClose={this.handleCloseAlert} severity={alertType} sx={{ width: '100%' }}>
        {alertMessage}
        </Alert>
        </Snackbar>
        <Modal
        open={this.state.deleteConfirmationShown}
        onClose={this.handleCloseModals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={{mb:5, textAlign:'center'}}>Are you sure you want to delete <b>{this.state.activeMaterialName}</b>?</Typography>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
                  <Button 
                  color="success" 
                  variant="contained" 
                  startIcon={<EditIcon/>}
                  onClick={()=>this.deleteItem(this.state.activeRowIndex)}
                  >Yes, Delete</Button>
                  <Button 
                  color="error" 
                  variant="contained" 
                  startIcon={<DeleteIcon/>}
                  onClick={this.handleCloseModals}
                  >No, Don't</Button>
          </Stack>
        </Box>
       </Modal>  
        <Modal
        open={this.state.showOrderModal}
        onClose={this.handleCloseModals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <PrintButton contentId={"orderTableFinal"}/>
        <OrderStats orderData={this.state.currentlyEditingOrder}/>
        {this.state.confirmedOrder ? 
        <TableContainer id="orderTableFinal" sx={{maxHeight:'40vh'}} component={Paper}>
          <Table aria-label="simple table" stickyHeader>
            <TableBody>
           {orderItemsArr.map((cat,catIndex) => { 
            return  cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).length ? <TableRow key={`${cat}-catRow-${catIndex}`}><TableCell>
                  <Typography className="onlyForPrint orderNumber" sx={{fontWeight:'bold'}}>{this.state.currentlyEditingOrder.branch} Order - #{this.state.currentlyEditingOrder.id}</Typography>
                    <Typography className="onlyForPrint orderDate">Order Date : {moment(this.state.currentlyEditingOrder.createdAt).format("ddd, DD-MMM-YYYY")}</Typography>
          
            <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              {/* FIRST CELL HEADING */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} className={"inventoryCategory"} align="left">{cat.category}</TableCell>
              {/* SECOND CELL HEADING */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Amount Ordered</TableCell>
              {/* THIRD CELL HEADING */}
              { this.state.currentlyEditingOrder.status !== "Order Placed" ? <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">{(this.state.confirmedOrder && ["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ? "Amount Received" : "Amount to Send"}</TableCell> : null }
              {/* FOURTH CELL HEADING */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left" className="onlyForPrint">Done</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
              {cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).map((row,index) => (
              <TableRow
              key={`${row}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              {/* FIRST CELL */}
              <TableCell align="left" component="th" scope="row" sx={{width:'30%'}}>
              <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
              {row.name}
              </Typography>
              </TableCell>
              {/* SECOND CELL */}
              <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center'}}>
                {/* {(this.state.confirmedOrder && ["Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                (processedItemsObj[row.id] ? processedItemsObj[row.id].qty : 0):(orderItemsObj[row.id] ? orderItemsObj[row.id].qty : 0)
                } units {(this.state.confirmedOrder && ["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                `${processedItemsObj[row.id] ? (parseFloat(processedItemsObj[row.id].qty) * parseFloat(row.unitSize)) : 0} ${row.unit}` : ''}
                 */}
                {(this.state.confirmedOrder && ["Order Placed","Acknowledged","Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                (orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty)} units` : '') : ''
                } {(this.state.confirmedOrder && ["Order Placed","Acknowledged","Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                `(${orderItemsObj[row.id] ? (parseFloat(orderItemsObj[row.id].qty) * parseFloat(row.unitSize)) : 0} ${row.unit})` : ''}
                </Typography>
                </Stack>
              </TableCell>
              {/* THIRD CELL */}
              { this.state.currentlyEditingOrder.status !== "Order Placed" ? <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center',color:(this.state.confirmedOrder ?
                (parseFloat(orderItemsObj[row.id].qty) !== parseFloat(processedItemsObj[row.id].qty) ? "red" : "black") : 'unset'),fontWeight:(this.state.confirmedOrder ?
                  (parseFloat(orderItemsObj[row.id].qty) !== parseFloat(processedItemsObj[row.id].qty) ? "bold" : "unset") : 'unset')}}>
                {(this.state.confirmedOrder && ["Acknowledged","Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                (processedItemsObj[row.id] ? `${parseFloat(processedItemsObj[row.id].qty)} units` : '') : ''
                } {(this.state.confirmedOrder && ["Acknowledged","Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                `(${processedItemsObj[row.id] ? (parseFloat(processedItemsObj[row.id].qty) * parseFloat(row.unitSize)) : 0} ${row.unit})` : ''}
                </Typography>
                </Stack>
              </TableCell> : null}
              {/* FOURTH CELL */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left" className="onlyForPrint"></TableCell>
              </TableRow>
              ))}
              </TableBody>
            </Table>      
            </TableCell>
          </TableRow> : '';}
          )}
          </TableBody></Table>
          </TableContainer> : <TableContainer id="orderTableFinal" sx={{maxHeight:'60vh'}} component={Paper}>
          <Typography className="onlyForPrint">{this.state.currentlyEditingOrder.branch} Order</Typography>
          <Table aria-label="simple table" stickyHeader>
            <TableBody>
           {orderItemsArr.map((cat,_) => { 
            return  cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).length ? <TableRow><TableCell>
                      <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              {/* FIRST CELL HEADING */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">{cat.category}</TableCell>
              {/* SECOND CELL HEADING */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Amount Ordered</TableCell>
              {/* THIRD CELL HEADING */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Edit Amount to Send</TableCell>
              {/* FOURTH CELL HEADING */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left" className="onlyForPrint">Done</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
              {cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).map((row,index) => (
              <TableRow
              key={`${row}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              {/* FIRST CELL */}
              <TableCell align="left" component="th" scope="row" sx={{width:'30%'}}>
                <Typography variant="subtitle1" component="div" sx={{textAlign:'left'}}>
              {row.name}
              </Typography>
              </TableCell>
              {/* SECOND CELL */}
              <TableCell align="left">
              <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
              <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center'}}>
              {orderItemsObj[row.id] ? orderItemsObj[row.id].qty : 0} units {orderItemsObj[row.id] ? `(${parseFloat(orderItemsObj[row.id].qty * row.unitSize)} ${row.unit})` : ''}
              </Typography>
              </Stack>
              </TableCell>
              {/* THIRD CELL */}
              <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Button color="error" variant="contained" children={<MinusIcon />} onClick={()=>{this.decreaseItemQuantity(row.id,row.incrementUnit)}}/>
                <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center'}}>
                {processedItemsObj[row.id] ? processedItemsObj[row.id].qty : 0}
                </Typography>
                <Button color="success" variant="contained" children={<AddIcon />} onClick={()=>{this.increaseItemQuantity(row.id,row.incrementUnit)}}/>
                <Typography variant="subtitle1" component="div" sx={{minWidth:50,textAlign:'center'}}>
                {processedItemsObj[row.id] ? `${parseFloat(processedItemsObj[row.id].qty * row.unitSize)} ${row.unit}` : ''}
                </Typography>
                </Stack>
              </TableCell>
              {/* FOURTH CELL */}
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left" className="onlyForPrint"></TableCell>
            </TableRow>
              ))}
              </TableBody>
            </Table>      
            </TableCell>
          </TableRow> : '';}
          )}
          </TableBody></Table>
          </TableContainer>}
          {(["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status) && !Utilities.isNullOrEmpty(this.state.currentlyEditingOrder.note))? 
        <Typography
          sx={{mt:2}}
        >
          <b>Note: </b>{this.state.currentlyEditingOrder.note}
        </Typography>
         : null}
          {(this.state.confirmedOrder && this.state.currentlyEditingOrder.orderedBy) ? 
        <TextField
          sx={{mt:3}}
          fullWidth={true}
          label="Order Placed By:"
          rows={1}
          disabled={true}
          value={this.state.currentlyEditingOrder.orderedBy}
        /> : null}
      <Stack spacing={1} direction={{ xs: 'column', sm: 'row'}} width={{ xs: '90%', sm: '100%'}} justifyContent="flex-end" sx={{mt:2,ml:'auto',mr:'auto'}}>
      {(this.state.confirmedOrder && ["Acknowledged"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.goBack}>
        {'Edit'}
      </Button> : ''}
      {(!this.state.confirmedOrder && ["Acknowledged"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.confirmOrder}>
        {'Confirm'}
      </Button> : ''}
      {(this.state.confirmedOrder && ["Acknowledged"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.updateOrder} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj)}>
      {'Update Order'}
    </Button> : ''}
    {(this.state.confirmedOrder && ["Acknowledged"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.dispatchOrder} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj)}>
      {'Dispatch Order'}
    </Button> : ''}
    {(this.state.confirmedOrder && ["Order Placed"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.acceptOrder} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj)}>
      {'Acknowledge Order'}
    </Button> : ''}
      <Button color="error" variant="contained" onClick={this.handleCloseModals}>
        {'Close'}
      </Button>
      </Stack>
      </Box>
        </Box>
        </Modal>
        <Box
          sx={{ flexGrow: 1, bgcolor: '#008080', display: 'flex',padding:0}}
        >
          <StyledTabs
          value={tabValue}
          onChange={this.handleTabChange}
          aria-label="styled tabs example"
          >
            <StyledTab label="Pending" />
            <StyledTab label="Done" />
          </StyledTabs>
      </Box>
        <TabPanel value={tabValue} index={0}>
      {this.renderActionsTable()}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
      {this.renderActionsTable()}
      </TabPanel>
      </Box>
      </div>
    } 
}

export default Actions;