import React from 'react';
import './Login.css';
import GotTeaBigLogo from '../../../media/GotTeaNewLogo.svg';
import Utilities from '../../../js/Utilities';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const config = {
    apiKey: "AIzaSyAB10QoTXFcdUCaVSbZntf_d_cSj7OSjHM",
    authDomain: "gottea-3daa4.firebaseapp.com",
    databaseURL: "https://gottea-3daa4.firebaseio.com",
    projectId: "gottea-3daa4",
    storageBucket: "gottea-3daa4.appspot.com",
    messagingSenderId: "691417546594",
    appId: "1:691417546594:web:536ef6d0c379fb5947b63b",
    measurementId: "G-ED1W31MB1J"
  };

  // Configure FirebaseUI.
const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: 'popup',
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    signInSuccessUrl: '/',
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID
    ],
  };

  firebase.initializeApp(config);
class Login extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            username:'',
            password:'',
            errorMsg : ''
        }
      }

    componentDidMount(){
        window.scrollTo(0, 0); 
    }

    handleUsernameChange = (e)=>{
        this.setState({ username : e.target.value, errorMsg : '' });
    }

    handlePasswordChange = (e)=>{
        this.setState({ password : e.target.value , errorMsg : ''  });
    }

    render(){
        const {username, password, errorMsg } = this.state;
        const disabled = this.state.username === '' || this.state.password === '';
        return <div className="innerPageContainer loginMainContainer">
            <div className='loginBox'>
            <div className='inputContainer'>
                <img className='logo' src={GotTeaBigLogo} alt=''/>
            </div>
            {/* <div className='inputContainer'>
                <input placeholder="Username" onChange={this.handleUsernameChange} value={username} type='text' />
            </div>
            <div className='inputContainer'>
                <input placeholder="Password" onKeyDown={this.handleKeyDown} onChange={this.handlePasswordChange} value={password} type='password' />
            </div> */}
            { errorMsg ? <div className='errorContainer'>{errorMsg}</div> : ''}
            {/* <div className='inputContainer'>
                <div 
                    onClick={this.handleLogin}
                    className={`loginBtn ${disabled ? 'disabledBtn' : ''}`} 
                >
                    Log In
                </div>
            </div> */}
            <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>
      </div>
    }
}

export default Login;