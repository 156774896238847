import React, { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import PrivateRoute from './components/Common/PrivateRoute/PrivateRoute';
import Loader from './components/Common/Loader/Loader';
import Login from './components/Pages/Login/Login';
import Home from './components/Pages/Home/Home';
import Wastage from './components/Pages/Wastage/Wastage';
import Materials from './components/Pages/Materials/Materials';
import ConsumptionStats from './components/Pages/ConsumptionStats/ConsumptionStats';
import WastageStats from './components/Pages/WastageStats/WastageStats';
import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import './css/common.css';
import UserContext from './js/UserContext';
import { Box } from '@mui/material';

function App() {
  const [authUser, setAuthUser] = useState(null);
  const [adminDetails, setAdminDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);  // Add this line


  useEffect(() => {
    const unsubscribeAuth = firebase.auth().onAuthStateChanged(async user => {      
      setAuthUser(user);
      if (user) {
        // User is authenticated, fetch additional details from Firestore
        const userDoc = await firebase.firestore().collection('adminUsers').doc(user.uid).get();
        if (userDoc.exists) {
          setAdminDetails(userDoc.data());
        } else {
          setAdminDetails(null);
        }
      } else {
        setAdminDetails(null);
      }
      setIsLoading(false);  // Set loading to false once we have the user details
    });

    return () => unsubscribeAuth();
  }, []);

  console.log("Auth User:", authUser);
  console.log("Admin Details:", adminDetails);
  if (isLoading) {  // Add this check
    return <Box sx={{ width:'100vw', height:'100vh', display:"flex", justifyContent:'center',alignItems:'center'}}><Loader/></Box>;
  }
  
  return (
    <div className="App">
      <UserContext.Provider value={{ authUser, adminDetails }}>
      <Router>
        <div className="contentWrapper">
        {/* <Switch>
            <Route exact path="/" component={authUser ? Home : Login}/>
            {authUser ? <>
            <Route exact path="/wastage" component={Wastage}/>
            </> : null}
            {adminDetails && adminDetails.role === "admin" ? <>
            <Route exact path="/materials" component={Materials}/>
            <Route exact path="/stats" component={ConsumptionStats}/>
            </> : null}
            <Route path="/login" component={Login}/>
        </Switch> */}
        <Switch>
              <PrivateRoute exact path="/" component={Home} authUser={authUser} adminDetails={adminDetails}/>
              <PrivateRoute exact path="/wastage" component={Wastage} authUser={authUser} adminDetails={adminDetails} />
              <PrivateRoute exact adminOnly={true} path="/materials" component={Materials} authUser={authUser} adminDetails={adminDetails} />
              <PrivateRoute exact adminOnly={true} path="/stats" component={ConsumptionStats} authUser={authUser} adminDetails={adminDetails} />
              <PrivateRoute exact adminOnly={true} path="/wastage_stats" component={WastageStats} authUser={authUser} adminDetails={adminDetails} />
              <Route exact path="/login" component={Login} />
            </Switch>
        </div>
      </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
