import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import './WastageStats.css';
import Loader from '../../Common/Loader/Loader';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableRow from '@mui/material/TableRow';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Box, TablePagination } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';
const Client = new Api();
const moment = require('moment-timezone');

const categories = [
  "Drink ingredients",
  "Food Ingredients",
  "Packaging",
  "Merchandise",
  "Disposables",
  "Petcan stickers (350 ml)",
  // "Petcan stickers (475 ml)",
  "Snacks box Sticker"
];

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.75),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  width:'100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));


class WastageStats extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentPage:0,
            consumptionTypes:[],
            branches:[],
            viewMode:'day',
            selectedBranches:["Safdarjung","GreaterKailash2","32ndAveGurgaon","Galleria"],
            currentMonth:moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM'),
            startDate : moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM-DD'),
            endDate : moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM-DD'),
            pageSize: 25,
            searchTerm:'',
            loading:false,
            consumptionData:[],
            materialCategory:'Drink ingredients',
            editMode:false,
            activeMatId:'',
            selectedUnit:'g',
            selectedIncrement:0.5,
            selectedStatus:'Active',
            selectedDisplayOrder:0,
            fieldErrors:{},
            showAddModal:false,
            deleteConfirmationShown:false,
            sortColumn: null,
            sortDirection: 'desc', // or 'asc'
            browser : Utilities.getCurrentBrowser(),
        }
    }

    loadData = ()=>{

      const {startDate, endDate, viewMode, selectedBranches, currentMonth } = this.state;
      let startDateString = moment.tz(startDate,'Asia/Kolkata').format('YYYY-MM-DD');
      let endDateString = moment.tz(endDate,'Asia/Kolkata').format('YYYY-MM-DD');
      
      if(viewMode === "month"){
          startDateString = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
          endDateString   = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
      }
      
        this.setState({
            loading:true
        },()=>{
            Client.searchConsumptions(this.state.currentPage + 1, this.state.pageSize, moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf(),["Wastage"], selectedBranches).then((data)=>{
                this.setState({
                    loading:false,
                    consumptionData:data
                },()=>{
                  this.sortData();
                });
            })
        })
    }

    handlePageChange = (event, value) => {
      this.setState({ currentPage: value });
    }

    handleRowsPerPageChange = (event) => {
      this.setState({ pageSize: parseInt(event.target.value, 10), currentPage: 0 });
    }

    componentDidMount(){
        this.loadData();
    }

    handleSearch = (e)=>{
      this.setState({
          currentPage:0,
          searchTerm:e.target.value
      });
    }

    showEditModal = (materialId)=>{
      Client.getMaterial(materialId).then((materialData)=>{
        this.setState({
          activeMatId:materialId,
          selectedIncrement:materialData.incrementUnit,
          selectedStatus:materialData.status || "Active",
          selectedDisplayOrder:materialData.displayOrder || 0,
          materialCategory:materialData.category,
          materialName:materialData.name,
          selectedUnit:materialData.unit,
          pricePerUnit:materialData.pricePerUnit,
          unitSize:materialData.unitSize,
          showAddModal:true,
          editMode:true
        });
      })
    }

    showDeleteModal = (materialName, materialId)=>{
      this.setState({
        activeMatId:materialId,
        activeMaterialName:materialName,
        deleteConfirmationShown:true
      });
    }

    handleFieldChange = (fieldName, e)=>{
      let fieldErrorsCopy = {...this.state.fieldErrors};
      delete fieldErrorsCopy[fieldName];
      this.setState({
        [`${fieldName}`]:e.target.value,
        fieldErrors:fieldErrorsCopy
      },()=>{

      });
    }
    
    handleChangeMode = (event, mode)=>{
      let updateObj = {
          viewMode : mode
      };
      this.setState(updateObj,()=>{
          this.loadData();
      });
    }

    setStartDate = (e) => {
      this.setState({startDate : e.target.value});
    }

    handleBranchesChange = (event) => {
      this.setState({
        selectedBranches: event.target.value,
      });
    };

  setMonth = async (e) => {
      const startDate = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
      const endDate   = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
      this.setState({
          currentMonth : e.target.value,
          startDate, 
          endDate
      });
  }

  onUpdateClick = ()=>{
      this.loadData();
  }

  setEndDate = (e) => {
      this.setState({endDate : e.target.value});
  }

  handleSort = (column) => {
    const isAsc = this.state.sortColumn === column && this.state.sortDirection === 'asc';
    this.setState({
      sortColumn: column,
      sortDirection: isAsc ? 'desc' : 'asc',
    }, this.sortData);
  };

  sortData = () => {
    const { sortColumn, sortDirection, consumptionData } = this.state;
    const sortedData = consumptionData.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
    this.setState({ consumptionData: sortedData });
  };

  render(){
        const {searchTerm, consumptionData, startDate, endDate, currentMonth, loading, viewMode, selectedBranches, currentPage, pageSize, sortColumn, sortDirection} = this.state;
          // Filter the rows based on search term
          let currentTotal = 0.0;
          const filteredData = searchTerm
        ? consumptionData.filter((row) =>
            row.itemName.toLowerCase().includes(searchTerm.toLowerCase())
          )
        : consumptionData;  // Filter the rows based on search term
        filteredData.map((item)=>{
          currentTotal += parseFloat(item.monetaryValue);
          return null;
        })
        const totalItems = filteredData.length;
        return  <div className='wastageStatsContainer'>
          <ReportsHeader/>
          <Box className='infoDiv'>
            <ToggleButtonGroup
                style={{marginTop:20, marginBottom:10}}
                color="primary"
                disabled={loading}
                value={viewMode}
                exclusive
                onChange={this.handleChangeMode}
            >
                <ToggleButton disabled={loading} value="day">Day</ToggleButton>
                <ToggleButton disabled={loading} value="month">Month</ToggleButton>
            </ToggleButtonGroup>
            <FormControl sx={{ minWidth:'320px', my: '1em'}}>
                <InputLabel>Branches</InputLabel>
                <Select
                color="primary"
                multiple
                value={selectedBranches}
                label="Branches"
                onChange={this.handleBranchesChange}
                >
                <MenuItem disabled={loading} value="Safdarjung">Safdarjung</MenuItem>
                <MenuItem disabled={loading} value="GreaterKailash2">GK2</MenuItem>
                <MenuItem disabled={loading} value="32ndAveGurgaon">32nd Ave</MenuItem>
                <MenuItem disabled={loading} value="Galleria">Galleria</MenuItem>
                </Select>
            </FormControl>
            <Box className="totalField">Total Monetary Value: ₹{Utilities.formatIndianCurrency(currentTotal)}</Box>
            <Box className='dateContainer'>
                    {viewMode === "day" ? <>                    
                    <input disabled={loading} type='date' className='dateInput' onChange={this.setStartDate} value={startDate}/>
                    <input disabled={loading} type='date' className='dateInput' onChange={this.setEndDate} value={endDate}/>
                    </>  : ''}
                    {viewMode === "month" ? <>
                    <input disabled={loading} type='month' className='dateInput' onChange={this.setMonth} value={currentMonth}/>
                    </> : ''}
                    <div className='updateBtn' onClick={this.onUpdateClick}> 
                    <FontAwesomeIcon className='btnIcon' icon={faArrowCircleRight} size='1x'/>
                    </div>
            </Box>
        <Stack spacing={1} sx={{minWidth:"400px"}}>
        <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          value={this.state.searchTerm}
          onChange={this.handleSearch}
          placeholder="Material Name"
          inputProps={{ 'aria-label': 'search' }}
        />
        </Search>
        </Stack>
        </Box>
        
        <TableContainer sx={{margin:4}} component={Paper}>
        <TablePagination 
        component="div"
        rowsPerPageOptions={[25,50,100]}
        count={totalItems} 
        page={this.state.currentPage} 
        onPageChange={this.handlePageChange} 
        rowsPerPage={this.state.pageSize}
        onRowsPerPageChange={this.handleRowsPerPageChange}
        />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>

          <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                <TableSortLabel
                  active={sortColumn === 'category'}
                  direction={sortColumn === 'category' ? sortDirection : 'asc'}
                  onClick={() => this.handleSort('category')}
                >
                  Category
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                <TableSortLabel
                  active={sortColumn === 'itemName'}
                  direction={sortColumn === 'itemName' ? sortDirection : 'asc'}
                  onClick={() => this.handleSort('itemName')}
                >
                  Item Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                <TableSortLabel
                  active={sortColumn === 'monetaryValue'}
                  direction={sortColumn === 'monetaryValue' ? sortDirection : 'asc'}
                  onClick={() => this.handleSort('monetaryValue')}
                >
                  Monetary Value
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                <TableSortLabel
                  active={sortColumn === 'value'}
                  direction={sortColumn === 'value' ? sortDirection : 'asc'}
                  onClick={() => this.handleSort('value')}
                >
                  Amount
                </TableSortLabel>
              </TableCell>
            </TableRow>


            {/* <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Category</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Item Name</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">
                Monetary Value</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }} align="left">Amount</TableCell>
            </TableRow> */}
          </TableHead>
          <TableBody>
            {this.state.loading ? <TableRow
                key={'loader'}
              >
                <TableCell component="th" scope="row" colSpan={4} sx={{textAlign:'center'}}>
                <Loader/>
                </TableCell>
                </TableRow> : <>{filteredData.slice(currentPage * pageSize, currentPage * pageSize + pageSize).map((row,index) => (
              <TableRow
                key={`${row.itemId}-${index}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="left" component="th" scope="row">
                  {row.category}
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  {row.itemName}
                </TableCell>
                <TableCell align="left" component="th" scope="row">
                  ₹{Utilities.formatIndianCurrency(row.monetaryValue)}
                </TableCell>
                <TableCell align="left">{row.value} {row.unit}</TableCell>
              </TableRow>
            ))}</>}
            
          </TableBody>
        </Table>
      </TableContainer>
      </div>
    } 
}

export default WastageStats;